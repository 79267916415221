import { Suspense, lazy, useEffect, useState, useMemo } from 'react';
import { StyledEngineProvider, CircularProgress, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import enLocale from 'date-fns/locale/en-GB';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { fetchProfile } from './auth/authActions';
import { AuthService } from './auth/AuthService';
import { REFRESH_TOKEN_SUCCESS, RESET_TOKEN_SUCCESS } from './auth/authTypes';
import { addTokenToRequest } from './auth/interceptor';
import { Notifier } from './components/notification/Notifier';
import TopBar from './components/top-bar/TopBar';
import muiTheme from './muiTheme';
import { getStatusOptions } from '../store/constant-state/action';
import './scss/global.scss';
import Parent from './Parent';
import jwt_decode from 'jwt-decode';
import store from '../store';
import {
  COUNTRIES_FILTER_CALLS,
  COUNTRY_FILTER_CALLS,
  CUSTOM_COUNTRY_FILTER_CALLS,
} from './constants/api-constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { Helmet } from 'react-helmet';
import { decideHomePage } from '../access-control/helpers';
import PageControl from './components/access-control/PageControl';
import { getUniqueUserCountries } from '../helpers/user.helpers';
import { fetchProfileFeatures, fetchFeaturesForCountries, fetchFeaturesForTeams } from '../new-store/features/actions';

import AccessDenied from '../pages/accessDenied';
import { featureSelector } from '../new-store/features/selectors';

const { NODE_ENV, REACT_APP_MUI_X_PRO_LICENSE_KEY } = process.env;

const LightView = lazy(() => import('../pages/light-view'));
const ContentProduction = lazy(() => import('../pages/content-production'));
const CampaignOverview = lazy(() => import('../pages/campaigns'));
const CampaignList = lazy(() => import('../pages/campaigns/list'));
const CreateCampaign = lazy(() => import('./pages/campaign-planning/create-campaign/CreateCampaign'));
const CustomerJourney = lazy(() => import('./pages/customer-journey/CustomerJourney'));
const Profile = lazy(() => import('./pages/campaign-profile/profile'));
const SwitchForCustomerJourney = lazy(() => import('./pages/switch-editor/SwitchForCustomerJourney'));
const SwitchForWebsite = lazy(() => import('./pages/switch-editor/SwitchForWebsite'));
const SwitchForEvent = lazy(() => import('./pages/switch-editor/SwitchForEvent'));
const SwitchForUserTemplate = lazy(() => import('./pages/switch-editor/SwitchForUserTemplate'));
const SwitchLayout = lazy(() => import('./pages/switch-editor/Layout'));
const PageNotFound = lazy(() => import('./helper/error'));

const WebsiteOverview = lazy(() => import('../pages/websites'));
const WebsiteCreation = lazy(() => import('../pages/websites/create'));
const SwitchWebsiteList = lazy(() => import('../pages/websites/list'));
const BriefList = lazy(() => import('../pages/briefs/list'));
const BriefCreation = lazy(() => import('../pages/briefs/create'));
const BriefEdition = lazy(() => import('../pages/briefs/edit'));
const BriefRequestCreation = lazy(() => import('../pages/briefRequests/create'));
const BriefRequestEdition = lazy(() => import('../pages/briefRequests/edit'));

LicenseInfo.setLicenseKey(REACT_APP_MUI_X_PRO_LICENSE_KEY);

axios.interceptors.request.use(
  req => {
    let params = {};
    if (
      new RegExp(
        `${[
          ...COUNTRY_FILTER_CALLS,
          ...COUNTRIES_FILTER_CALLS,
          ...CUSTOM_COUNTRY_FILTER_CALLS,
        ].join('|')}`,
      ).test(req.url)
    ) {
      const campaignCountryIds = store
        .getState()
        .campaignProfile?.campaignProfile?.campaignDetails?.countries.map(country => country.id)
        .join(',');
      if (campaignCountryIds?.length) {
        switch (true) {
          case COUNTRY_FILTER_CALLS.length &&
            new RegExp(`${COUNTRY_FILTER_CALLS.join('|')}`).test(req.url):
            params = { filter: `country.id||$in||${campaignCountryIds}` };
            break;
          case COUNTRIES_FILTER_CALLS.length &&
            new RegExp(`${COUNTRIES_FILTER_CALLS.join('|')}`).test(req.url):
            params = { filter: `countries.id||$in||${campaignCountryIds}` };
            break;
          case CUSTOM_COUNTRY_FILTER_CALLS.length &&
            new RegExp(`${CUSTOM_COUNTRY_FILTER_CALLS.join('|')}`).test(req.url):
            params = { countryIds: campaignCountryIds };
            break;
          default:
        }
      }
    }

    req.params = {
      ...req.params,
      ...params,
    };

    return req;
  },
  err => Promise.reject(err),
);

const MainLayout = () => (
  <>
    <TopBar />
    <Outlet />
  </>
);

const AsyncPageNotFound = () => {
  return (
    <Suspense fallback={null}>
      <PageNotFound />
    </Suspense>
  )
}

const AsyncCreateCampaign = () => {
  return (
    <Suspense fallback={null}>
      <CreateCampaign />
    </Suspense>
  )
}

const AsyncLightView = () => {
  return (
    <Suspense fallback={null}>
      <PageControl code='light_view' fallback='/'>
        <LightView />
      </PageControl>
    </Suspense>
  )
}

const AsyncContentProduction = () => {
  return (
    <Suspense fallback={null}>
      <PageControl code='content_production'>
        <ContentProduction />
      </PageControl>
    </Suspense>
  )
}

const AsyncCampaignOverview = () => {
  return (
    <Suspense fallback={null}>
      <PageControl code='default_view' fallback='/'>
        <CampaignOverview />
      </PageControl>
    </Suspense>
  )
}

const AsyncCampaignList = ({ myList }) => {
  return (
    <Suspense fallback={null}>
      <CampaignList myList={myList} />
    </Suspense>
  )
}

const AsyncCustomerJourneyCanvas = () => {
  return (
    <Suspense fallback={null}>
      <PageControl code='customer_journey'>
        <CustomerJourney />
      </PageControl>
    </Suspense>
  )
}

const AsyncProfile = () => {
  return (
    <Suspense fallback={null}>
      <PageControl code='campaign_profile'>
        <Profile />
      </PageControl>
    </Suspense>
  )
}

const AsyncSwitchForWebsite = ({ setStepData }) => {
  return (
    <Suspense fallback={null}>
      <SwitchForWebsite setStepData={setStepData} />
    </Suspense>
  )
}

const AsyncSwitchForCustomerJourney = ({ setStepData }) => {
  return (
    <Suspense fallback={null}>
      <SwitchForCustomerJourney setStepData={setStepData} />
    </Suspense>
  )
}

const AsyncSwitchForEvent = ({ setStepData }) => {
  return (
    <Suspense fallback={null}>
      <SwitchForEvent setStepData={setStepData} />
    </Suspense>
  )
}

const AsyncSwitchForUserTemplate = ({}) => {
  return (
    <Suspense fallback={null}>
      <SwitchForUserTemplate />
    </Suspense>
  )
}

const AsyncSwitchLayout = ({ stepData }) => {
  return (
    <Suspense fallback={null}>
      <SwitchLayout stepData={stepData} />
    </Suspense>
  )
}

const AsyncWebsiteOverview = () => {
  return (
    <Suspense fallback={null}>
      <WebsiteOverview />
    </Suspense>
  )
}

const AsyncWebsiteCreation = () => {
  return (
    <Suspense fallback={null}>
      <WebsiteCreation />
    </Suspense>
  )
}

const AsyncSwitchWebsiteList= ({ myList }) => {
  return (
    <Suspense fallback={null}>
      <SwitchWebsiteList myList={myList} />
    </Suspense>
  )
}

const AsyncBriefList = ({ myList }) => {
  return (
    <Suspense fallback={null}>
      <BriefList myList={myList} />
    </Suspense>
  )
}

const AsyncBriefCreation = () => {
  return (
    <Suspense fallback={null}>
      <BriefCreation />
    </Suspense>
  )
}

const AsyncBriefEdition = () => {
  return (
    <Suspense fallback={null}>
      <BriefEdition />
    </Suspense>
  )
}

const AsyncBriefRequestCreation = () => {
  return (
    <Suspense fallback={null}>
      <BriefRequestCreation />
    </Suspense>
  )
}

const AsyncBriefRequestEdition = () => {
  return (
    <Suspense fallback={null}>
      <BriefRequestEdition />
    </Suspense>
  )
}

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const background = location.state && location.state.background;
  const [stepData, setStepData] = useState({ hasChanges: false, stepType: '' });
  const [refreshChecked, setRefreshChecked] = useState(false);
  const currentUser = useSelector(({ authentication }) => authentication.profile);
  const featureState = useSelector(featureSelector);

  useEffect(() => {
    // validate token is  about to expired
    let persist = JSON.parse(localStorage.getItem('persist:orchestra'));

    if (typeof persist !== 'undefined') {
      let token = JSON.parse(persist.authentication).accessToken;

      //code to bipass local static token
      if (
        token !== 'Bearer local_access_token' &&
        typeof token !== 'undefined' &&
        NODE_ENV !== 'development'
      ) {
        const { exp } = jwt_decode(token);

        // Refresh the token a minute early to avoid latency issues
        const expirationTime = exp * 1000 - 60000;

        if (Date.now() >= expirationTime) {
          localStorage.clear();
          dispatch({ type: RESET_TOKEN_SUCCESS });
          window.location.reload();
        } else {
          const authService = new AuthService(dispatch);
          addTokenToRequest(authService);
          createAuthRefreshInterceptor(axios, async failedRequest => {
            const tokens = await authService.refreshToken();
            dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: tokens });

            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokens.accessToken;

            return Promise.resolve();
          });
        }
      } else {
        const authService = new AuthService(dispatch);
        addTokenToRequest(authService);
        createAuthRefreshInterceptor(axios, async failedRequest => {
          const tokens = await authService.refreshToken();
          dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: tokens });

          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokens.accessToken;

          return Promise.resolve();
        });
      }
    }

    setRefreshChecked(true);

    dispatch(fetchProfile());
    dispatch(getStatusOptions());
  }, [dispatch]);

  useEffect(() => {
    if (!refreshChecked) return;

    if (!featureState.profile.fullfilled) {
      dispatch(fetchProfileFeatures(currentUser.profile?.id));
    }

    if (!featureState.country.fullfilled) {
      dispatch(fetchFeaturesForCountries(getUniqueUserCountries(currentUser).map(country => country.id)));
    }

    if (!featureState.team.fullfilled) {
      dispatch(fetchFeaturesForTeams(currentUser.teams ? currentUser.teams.map(team => team.id) : []));
    }
  }, [
    dispatch,
    featureState.profile.fullfilled,
    featureState.country.fullfilled,
    featureState.team.fullfilled,
    refreshChecked
  ]);

  const defaultPath = useMemo(() => {
    return decideHomePage(currentUser, featureState.country.data, featureState.profile.data, featureState.team.data);
  }, [currentUser, featureState.country.data, featureState.profile.data, featureState.team.data]);

  if (!(featureState.profile.fullfilled && featureState.country.fullfilled && featureState.team.fullfilled)) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <p>Authenticating</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enLocale}
          dateFormats={{ keyboardDate: 'dd-MM-yyyy' }}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={2000}
            sx={{
              backgroundColor: '00a0df'
            }}
          >
            <CssBaseline />
            <Notifier />
            <Parent>
              <Helmet>
                <title>Orchestra</title>
              </Helmet>

              <Routes location={background || location}>
                {/* This is required because Switch use a custom Layout (Switch-specific TopBar) */}
                <Route path="/switch" element={<AsyncSwitchLayout stepData={stepData} />}>
                  { /* when user comes from Customer Journey with a stepDisplayID in URL */ }
                  <Route exact path="email/:stepDisplayId" element={<AsyncSwitchForCustomerJourney setStepData={setStepData} />} />
                  {/* website entry point (linked to an existing ORC_SWITCH_WEBSITE_PAGE.id) */}
                  <Route exact path="website/page/:displayId" element={<AsyncSwitchForWebsite setStepData={setStepData} />} />
                  { /* when user comes from Home Page "Event fragment" flow */ }
                  <Route exact path="event/:campaignDisplayId/:stepDisplayId/:eventNumber/:templateId" element={<AsyncSwitchForEvent setStepData={setStepData} />} />
                  <Route exact path="fragment/:fragmentDisplayId" element={<AsyncSwitchForEvent setStepData={setStepData} />} />
                  { /* when user edit a user template */ }
                  <Route exact path="template/:userTemplateId" element={<AsyncSwitchForUserTemplate />} />
              </Route>
              <Route element={<MainLayout />}>
                <Route exact path="websites/create" element={<AsyncWebsiteCreation />} />
                <Route path="websites" element={<AsyncWebsiteOverview />}>
                  <Route exact path="list" element={<AsyncSwitchWebsiteList myList />} />
                </Route>
                <Route path="briefs">
                  <Route index element={<AsyncBriefList myList />} />
                  <Route exact path="create" element={<AsyncBriefCreation />} />
                  <Route exact path="edit/:id" element={<AsyncBriefEdition />} />
                  <Route exact path=":brief_id/requests/create" element={<AsyncBriefRequestCreation />} />
                  <Route exact path=":brief_id/requests/edit/:request_id" element={<AsyncBriefRequestEdition />} />
                </Route>

                <Route exact path="/customer-journey/:id" element={<AsyncCustomerJourneyCanvas />} />
                <Route exact path="/customer-journey/:id/:nodeId" element={<AsyncCustomerJourneyCanvas />} />
                <Route exact path="/campaign/profile/:campaign_id" element={<AsyncProfile />} />
                <Route exact path="/campaign/profile/:campaign_id/:node_id" element={<AsyncProfile />} />
                <Route path="/campaign" element={<AsyncCampaignOverview />}>
                  <Route index element={<AsyncCampaignList myList />} />
                  <Route path="list" element={<AsyncCampaignList />} />
                  <Route path="my-list" element={<AsyncCampaignList myList />} />
                  {/* adding this route, as we have to show the mylist page */}
                  {/* below and the campagin creation page above when on /campaign/creation route */}
                  {/* this works without the below route, but if fails when directly visiting /campaign/route . so adding it */}
                  <Route path="create" element={<AsyncCampaignList myList />} />
                  <Route path="" element={<Navigate to={`/campaign/my-list`} replace={true} />} />
                  <Route element={<AsyncPageNotFound />} />
                </Route>
                <Route path="/campaign-builder" element={<AsyncLightView />} />
                <Route path="/content-production" element={<AsyncContentProduction />} />
                <Route path="/content-production/:step_id" element={<AsyncContentProduction />} />
                <Route path="/access-denied" element={<AccessDenied />} />
                {/* Adding the auth_callback path to navigate to the appropriate page after authentication */}
                <Route
                  path="/auth_callback"
                  element={<Navigate to={defaultPath} replace />}
                />
                <Route path="/" element={<Navigate to={defaultPath} replace />} />
                <Route path="" element={<Navigate to={defaultPath} replace />} />
              </Route>
              <Route element={<PageNotFound />} />
            </Routes>
            {(background || location.pathname.includes('create')) && (
              <Routes>
                <Route path={`/campaign/create`} element={<AsyncCreateCampaign />} />
              </Routes>
            )}
            </Parent>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
