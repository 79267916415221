import axios from '../../api';
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { INotificationMailData } from './types';

const generalRelations = [
    'status',
    'type',
    'tags',
    'tags.tag',
    'tags.tagCategory',
    'emailType',
    'emailSubtype',
    'attachments',
    'attachments.createdBy',
    'urls',
    'createdBy',
    'requestOwner',
    'nodes',
    'nodes.journey',
];

const adminRelations = [
    'checklistProgress',
    'checklistProgress.item',
];

const retroplanningRelations = [
    'assignedTo',
    'publishedBy',
    'submittedBy',
    'switchEmail',
    'switchEmail.createdBy',
    'switchEmail.submittedBy'
];

const optionsRelations = [
    'internalAudience',
    'reminders',
];

const ctaRelations = [
    'cta',
    'cta.template',
];

const fragmentsRelations = [
    'fragments',
    'fragments.status'
];

const fragmentRelations = [
    'status',
    'createdBy',
    'tags',
    'tags.tag',
    'tags.tagCategory',
    'attachments',
    'attachments.createdBy',
    'urls',
    'cta',
    'cta.template',
    'checklistProgress',
    'checklistProgress.item',
    'requestOwner',
    'assignedTo',
    'publishedBy',
    'submittedBy',
    'step'
];

export const clearStepFormState = createAction('clearStepFormState');
export const clearFragmentState = createAction('clearFragmentState');

export const getCampaignData = createAsyncThunk('campaign', async (id: number) => {
    const response = await axios.get(`/campaigns/${id}`, {
        params: {
            join: ['countries', 'campaignType', 'product', 'product.therapeutic', 'team']
        }
    });

    return response.data;
});

export const getStepData = createAsyncThunk('step/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: generalRelations
        }
    });

    return response.data;
});

export const getStepAdminData = createAsyncThunk('step/admin/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: adminRelations
        }
    });

    return response.data;
});

export const getStepRetroplanningData = createAsyncThunk('step/retroplanning/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: retroplanningRelations
        }
    });

    return response.data;
});

export const getStepOptionsData = createAsyncThunk('step/options/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: optionsRelations
        }
    });

    return response.data;
});

export const getStepCtaData = createAsyncThunk('step/cta/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: ctaRelations
        }
    });

    return response.data;
});

export const getStepFragmentsData = createAsyncThunk('step/fragments/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: fragmentsRelations
        }
    });

    return response.data;
});

export const saveStepData = createAsyncThunk('step/patch', async (payload: { id: number, data: any }) => {
    const response = await axios.patch(`/steps/${payload.id}`, payload.data, {
        params: {
            join: generalRelations,
        }
    });

    return response.data;
});

export const getFragmentData = createAsyncThunk('fragment/get', async (id: number) => {
    const response = await axios.get(`/fragments/${id}`, {
        params: {
            join: fragmentRelations
        }
    });

    return response.data;
});

export const saveFragmentData = createAsyncThunk('fragment/patch', async (payload: { id: number | null | undefined, data: any }) => {
    if (payload.id) {
        const response = await axios.patch(`/fragments/${payload.id}`, payload.data, {
            params: {
                join: fragmentRelations
            }
        });

        return response.data;
    } else {
        const response = await axios.post(`/fragments`, payload.data, {
            params: {
                join: fragmentRelations
            }
        });

        return response.data;
    }
});

export const deleteFragment = createAsyncThunk('fragment/delete', async (id: number) => {
    await axios.delete(`/fragments/${id}`);
});

export const getStepAttachments = createAsyncThunk('step/attachments/get', async (id: number) => {
    const response = await axios.get(`/steps/${id}`, {
        params: {
            join: ['attachments', 'attachments.createdBy']
        }
    });

    return response.data;
});

export const getFragmentAttachments = createAsyncThunk('fragment/attachments/get', async (id: number) => {
    const response = await axios.get(`/fragments/${id}`, {
        params: {
            join: ['attachments', 'attachments.createdBy']
        }
    });

    return response.data;
});

export const getTags = createAsyncThunk('tags', async (id: number) => {
    const response = await axios.get(`/tags/tags-by-step/${id}`);

    return response.data;
});

export const getCta = createAsyncThunk('cta', async (payload: { therapeuticId: number, stepTypeId: number }) => {
    const response = await axios.get(`/ctas/cta-templates/therapeutic/${payload.therapeuticId}`, {
        params: {
            stepTypeId: payload.stepTypeId
        }
    });

    return response.data;
});

export const getStaff = createAsyncThunk('staff', async (therapeutic: string) => {
    const response = await axios.get(`/steps/parent-calls`, {
        params: {
            therapeutic: therapeutic
        }
    });

    return response.data;
});

export const getKeyMessages = createAsyncThunk('keyMessages', async (payload: { product: string, therapeutic: string }) => {
    const response = await axios.get(`/steps/key-messages`, {
        params: {
            therapeutic: payload.therapeutic,
            product: payload.product
        }
    });

    return response.data;
});

export const getSurveys = createAsyncThunk('surveys', async () => {
    const response = await axios.get('/steps/surveys');

    return response.data;
});

export const getEventDetails = createAsyncThunk('eventDetails', async (eventNumber: string) => {
    const response = await axios.get(`/steps/event-details/${eventNumber}`);

    return response.data;
});

export const getImrDetails = createAsyncThunk('imrDetails', async (imrNumber: string) => {
    const response = await axios.get(`/steps/imr-details/${imrNumber}`);

    return response.data;
});

export const getLinkedFragments = createAsyncThunk('linkedFragments', async (stepId: number) => {
    const response = await axios.get(`/steps/${stepId}/linked-fragments`);

    return response.data;
});

export const getEmailTypes = createAsyncThunk('emailTypes', async () => {
    const response = await axios.get(`email-type`);

    return response.data;
});

export const getEmailSubtypes = createAsyncThunk('emailSubtypes', async () => {
    const response = await axios.get(`email-subtype`);

    return response.data;
});

export const uploadFileToS3 = createAsyncThunk('upload', async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post('attachments/s3/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
});

export const downloadFileFromS3 = createAsyncThunk('download', async (filename: string) => {
    const response = await axios.get(`/attachments/s3/download`, {
        params: {
            filename: filename
        },
        responseType: 'blob'
    });

    return response.data;
});

export const getUsers = createAsyncThunk('users', async (countries: string) => {
    const response = await axios.get('/users', {
        params: {
            countries: countries
        }
    });

    return response.data;
});

export const getIntegrationTeamMembers = createAsyncThunk('integrationMembers', async () => {
    const response = await axios.get('/team/integration/members');

    return response.data;
});

export const getTodoList = createAsyncThunk('todoList', async () => {
    const response = await axios.get('/steps/types?todos=1');

    return response.data;
});

export const sendNotificationMail = createAsyncThunk('notification', async (payload: { stepId: number, type: string }) => {
    await axios.post('/emailer/send-brief-notification', {
        id: payload.stepId,
        type: payload.type
    });
});

export const getSenders = createAsyncThunk('senders', async () => {
    const response = await axios.get('/team?filter=type.id||$eq||2');

    return response.data;
});

export const updateReminderDraft = createAction<any>('reminderDraft');

export const getKpis = createAsyncThunk('kpis', async (stepId: number) => {
    const response = await axios.get(`/steps/${stepId}/kpis`);

    return response.data;
});

export const getFragmentRetroplanning = createAsyncThunk('fragmentRetroplanning', async (fragmentDisplayId: string) => {
    const response = await axios.get(`/fragments/${fragmentDisplayId}/retroplanning`);

    return response.data;
});

export const getStepComments = createAsyncThunk('stepComments', async (payload: { id: number, isFragment?: boolean }) => {
    const url = payload.isFragment ? `/step-comments/by-fragment/${payload.id}` : `/step-comments/by-step/${payload.id}`;
    const response = await axios.get(url);

    return response.data;
});

export const createComment = createAsyncThunk('stepComments/create', async (payload: any) => {
    const response = await axios.post('/step-comments', payload);

    return response.data;
});

export const sendCommentNotificationMail = async (data: INotificationMailData) => {
    try {
      await axios.post(`/emailer/send-step-comments-notification`, data);
    } catch (error) {
      console.log('sending email notification failed', error);
    }
}

export const addThreadUser = createAsyncThunk('threadUser', async (payload: { id: number, data: any, isFragment?: boolean }) => {
    const url = payload.isFragment ? `/fragments/${payload.id}` : `/steps/${payload.id}`;
    const response = await axios.patch(url, payload.data);

    return { isFragment: payload.isFragment, data: response.data };
});
